import React from "react"
import Cookies from "universal-cookie"

const Agegate = props => {
  // let ageCookie = new Cookies()

  return (
    <>
      <div className="age-gate-text text-white px-5">
        <h4 className="">This website requires you to be 18+</h4>
        <h4 className="my-3 mt-md-0 text-yellow">Are you over 18?</h4>
        <button
          type="button"
          id="btn-agegate"
          className="btn btn-primary border-0 rounded-0 mt-3 text-uppercase"
          onClick={() => {
            props.setAgeGateShow(false)
            props.setShowQuizForm(true)
            // ageCookie.set("isOldEnough", "yes", { path: "/" })
          }}
        >
          Yes, Enter
        </button>
      </div>
    </>
  )
}

export default Agegate
