import React from "react"

const DividerLine = () => {
  return (
    <>
      <div className="question-divider-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="question-divider"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DividerLine
