import React from "react"

const QuizIntro = () => {
  return (
    <>
      <div className="bg-film-one bg-film-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-lg-6 offset-lg-3 text-center text-white">
              {/* <h2 className="mb-4">
                Believe it or not, we know the perfect recipe for you based on
                just 8 questions!
              </h2>
              <h5 className="mb-4">
                Simply answer the quiz questions below to receive a personalised
                recipe, not only that, you will receive the world's first Google
                AI machine recipe going head to head against the nation's
                favourite Bake off Winner Peter Sawkins.
              </h5>
              <h3 className="text-yellow">Can you bake against the machine?</h3> */}
              <h2 className="mb-4">
                Answer just 8 questions and we’ll send you what we think is your
                PERFECT Maltesers recipe!
              </h2>
              <h5 className="mb-4">
                Simply answer the quick quiz questions below and you’ll get a
                very special personalised recipe recommendation AND instructions
                on how to make the world’s very first Maltesers Artificial
                Intelligence cake, which has been developed by a special team of
                Google engineers.
              </h5>
              <h3 className="text-yellow">
                Do YOU have what it takes to bake against the machine?
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuizIntro
