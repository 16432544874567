import React from "react"
// import ScriptTag from "react-script-tag"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = props => {
  const data = useStaticQuery(graphql`
    query {
      logoMaltesersFooter: file(relativePath: { eq: "logo-maltesers.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <footer
        className={`main-footer d-block pb-5 ${
          props.showQuizForm ? null : "bg-drip-shape"
        }`}
      >
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center text-white">
              {/* <p className="mt-4">
                Promotion runs from 30.03.2021 – 26.04.2021. UK residents aged
                18+ only. One entry per person. See{" "}
                <a
                  className="text-yellow"
                  href="https://www.facebook.com/Maltesers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.facebook.com/Maltesers
                </a>{" "}
                for prize details and full T&amp;Cs.
              </p>

              <p className="mb-5">
                Promoter: Mars Wrigley Confectionery UK Limited, 3D Dundee Road,
                Slough, Berkshire, SL1 4LG.
              </p> */}

              <figure className="pt-2 m-auto logo-maltesers-footer">
                <Img
                  fluid={data.logoMaltesersFooter.childImageSharp.fluid}
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
