import React, { useRef } from "react"

// Images
import NinePM from "../../images/question-seven/9pm.jpg"
import ElevenPM from "../../images/question-seven/11pm.jpg"
import Midnight from "../../images/question-seven/midnight.jpg"
import ThreeAM from "../../images/question-seven/3am.jpg"

const QuestionSeven = ({ register, errors }) => {
  const questionSevenRef = useRef(null)
  function goToNext() {
    if (questionSevenRef.current) {
      questionSevenRef.current.scrollIntoView()
      window.scrollBy(0, questionSevenRef.current.offsetHeight + 30)
    }
  }
  return (
    <>
      <div className="bg-question-type-one" ref={questionSevenRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row no-gutters">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="question-title-hldr d-flex flex-column align-items-center">
                    <div className="question-number">7</div>
                    <h2 className="question-title my-4 text-white text-center">
                      You go to bed at…
                    </h2>
                    {/* <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                      1 Question Left
                    </span> */}
                  </div>
                </div>
              </div>

              <div className="row no-gutters">
                <div className="col-12">
                  {errors?.questionSeven && (
                    <span className="error d-inline-block mb-2 text-yellow">
                      Please select at least one option
                    </span>
                  )}
                </div>
                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-3 w-100">
                    <input
                      name="questionSeven"
                      type="radio"
                      value="9pm"
                      {...register('questionSeven', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={NinePM} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        9pm
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-3 w-100">
                    <input
                      name="questionSeven"
                      type="radio"
                      value="11pm"
                      {...register('questionSeven', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={ElevenPM} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        11pm
                      </p>
                    </div>
                  </label>
                </div>

                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-0 w-100">
                    <input
                      name="questionSeven"
                      type="radio"
                      value="Midnight"
                      {...register('questionSeven', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={Midnight} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Midnight
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-0 w-100">
                    <input
                      name="questionSeven"
                      type="radio"
                      value="3am"
                      {...register('questionSeven', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={ThreeAM} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        3am
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="text-center mt-5">
                <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                  1 Questions Left
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionSeven
